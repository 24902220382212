import { CommonModule, NgClass } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { AboutComponent } from "./about/about.component";
import { FooterComponent } from './footer/footer.component';
import { LandingComponent } from "./landing/landing.component";
import { NavComponent } from './nav/nav.component';
import { ThemeService } from './theme/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NavComponent,
        FooterComponent,
        RouterOutlet,
        CommonModule,
        AboutComponent,
        LandingComponent
    ]
})
export class AppComponent {
  title:string = 'Acra Dev';

  entered:boolean=true;

  //Cursor Variables
  outerCursorTop:string="";
  outerCursorLeft:string="";
  innerCursorTop:string="";
  innerCursorLeft:string="";
  
  //ThemeSetter
  get isDarkMode():boolean {
    return this.themeService.isDarkMode();
  }

  //TitleService dynamically changes Title of the webApp (seen in the browser tabs) based off the value above,this removes having to change it manually in the index.html <title></title>
  constructor( private titleService: Title, private themeService: ThemeService) {
    this.titleService.setTitle(this.title);
  }
  
  //Cursor Functionality
  @HostListener('document:mousemove', ['$event'])
  onMousemove($event: { clientY: number; clientX: number; }) {
      const cursorWidth = 35;
      const maxX = window.innerWidth - cursorWidth;
      const offsetY = Math.min($event.clientY - 17.5) + "px";
      const offsetX = Math.min($event.clientX - 17.5, maxX) + "px";
      
      this.outerCursorTop = offsetY;
      this.outerCursorLeft = offsetX;
  
      this.innerCursorTop = Math.min($event.clientY - 5) + "px";
      this.innerCursorLeft = Math.min($event.clientX - 5, maxX) + "px";
  }

}
