import { importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';


bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule),
        // provideRouter([
        //     {path:'',component:LandingComponent,},
        //     {path:'blog',component:BlogComponent},
        //     {path:'about',component:AboutComponent},
        //     {path:'projects',component:ProjectsComponent},
        //     {path:'connect',component:ContactComponent}
        // ])
    ]
})
  .catch(err => console.error(err));
