<section
  class="container-fluid mx-0 px-0 section row d-flex justify-content-center"
>
  <div class="col-sm-12 col-lg-3 d-flex align-center justify-content-center">
    <img
      class="img-fluid avatar"
      src="../../assets/AiAvatar.jpg"
      alt="AI Image Of Acra"
    />
  </div>
  <div class="col-sm-12 col-lg-4 landing-text">
    <div class="typing-header">
      <div class="landing-sub-text text-body-secondary mb-0 px-0">I Am A</div>
    </div>
    <div class="typing-container text-center">
      <span class="typed-text"></span>
      <span class="cursor">&nbsp;</span>
    </div>
    <div class="typing-container-2">
      <span class="landing-sub-text text-body-secondary"
        >launching ideas <br />
        on the world wide web, <br />
        one <span class="in-text">{{ inText }}</span> at a time.
      </span>
    </div>
  </div>
</section>
<app-scroller></app-scroller>
