import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BlogComponent } from "../blog/blog.component";
import { ThemeService } from '../theme/theme.service';
import { ScrollerComponent } from "./scroller/scroller.component";

@Component({
    selector: 'app-landing',
    standalone: true,
    templateUrl: './landing.component.html',
    styleUrl: './landing.component.scss',
    imports: [CommonModule, ScrollerComponent, BlogComponent]
})

export class LandingComponent implements OnInit {
  constructor(private themeService: ThemeService) { }

  get isDarkMode(): boolean {
    return this.themeService.isDarkMode();
  }

  typedTextSpan: any;
  cursorSpan: any;
  textArray = ["Software Developer"];
  typingDelay = 100;
  newTextDelay = 0;
  textArrayIndex = 0;
  charIndex = 0;
  inText: string = 'byte'

  ngOnInit(): void {
    this.typedTextSpan = document.querySelector(".typed-text");
    this.cursorSpan = document.querySelector(".cursor");
    // On DOM Load initiate the effect
    if (this.textArray.length) {
      setTimeout(() => this.type(), this.newTextDelay + 250);
    }
  }
  type(): void {
    if (this.charIndex < this.textArray[this.textArrayIndex].length) {
      if (!this.cursorSpan.classList.contains("typing")) {
        this.cursorSpan.classList.add("typing");
      }
      this.typedTextSpan.textContent += this.textArray[this.textArrayIndex].charAt(this.charIndex);
      this.charIndex++;
      setTimeout(() => this.type(), this.typingDelay);
    }
  }

}