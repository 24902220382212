<div class="container-fluid nav-main sticky-top" [ngClass]="isDarkMode?'nav-dark':'nav-light'">
  <div class="d-flex flex-wrap justify-content-sm-center justify-content-md-center justify-content-lg-around justify-content-between">
    <a class="logo-anchor" href="/">
      <img class="logo p-2" src={{logoUrl}} (mouseenter)="toggleLogo(true)" (mouseleave)="toggleLogo(false)" alt="Acra Dev Logo">
      <img class="mobile-logo" src="../../assets/MobileLogo.png" alt="Acra Dev Logo">
    </a>
    <nav class="navbar navbar-expand-sm bg-body-none">
      <div class="navbar-nav navLinks">
        <a href="/about" routerLinkActive="active" routerLink="/about" class="nav-link" >[About]</a>
        <a href="/blog" routerLinkActive="active" routerLink="/blog" class="nav-link">[Blog]</a>
        <a href="/projects" routerLinkActive="active" routerLink="/blog" class="nav-link">[Projects]</a>
        <a href="/connect" routerLinkActive="active" routerLink="/connect" class="nav-link">[Connect]</a>
        <button (click)="toggleTheme($event)" class="me-3 theme-button modal-btn" aria-label="Theme Toggle">
          <i class="d-flex align-items-center px-3 fa-solid fa-circle-half-stroke"></i>
        </button>
      </div>
      <div class="container d-flex">
        <button class="navbar-toggler dropdown" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header d-flex align-items-center">
                <button (click)="toggleTheme($event)" class="me-3 theme-button modal-btn" aria-label="Theme Toggle">
                  <i class="d-flex align-items-center fa-solid fa-circle-half-stroke"></i>
                </button>
                  <img class="mobile-nav-logo" src="assets/MobileLogo.png">
                <button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close">
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                <a href="/about" routerLinkActive="active" routerLink="/about" class="dropdown-item fs-2 p-2 text-body-secondary">[About]</a>
                <a href="/blog" routerLinkActive="active" routerLink="/blog" class="dropdown-item fs-2 p-2 mt-3 text-body-secondary">[Blog]</a>
                <a href="/projects" routerLinkActive="active" routerLink="/projects" class="dropdown-item fs-2 p-2 mt-3 text-body-secondary">[Projects]</a>
                <a href="/connect" routerLinkActive="active" routerLink="/connect" class="dropdown-item fs-2 p-2 mt-3 text-body-secondary">[Connect]</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>