<div class="container">
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-2">
        <div class="copyDiv col-md-4 d-flex">
            <i class="fa-regular fa-copyright fa-xl text-body-secondary mt-2 me-2"></i>
            <p class="copyText text-body-secondary">2023 AcraDev</p>
        </div>
        <div class="social-div">
            <span class="ms-3">
                <a class="text-body-secondary social-icon" href="https://www.linkedin.com/in/acra-jonathan/" aria-label="Visit LinkedIn">
                    <i class="fa-brands fa-linkedin fa-2xl"></i>
                </a>
            </span>
            <span class="ms-3">
                <a class="text-body-secondary social-icon" href="https://github.com/J-Acra?tab=repositories" aria-label="Visit GitHub">
                    <i class="fa-brands fa-square-github fa-2xl"></i>
                </a>
            </span>
        </div>
    </footer>
</div>