import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ThemeService } from '../theme/theme.service';
import { ExperienceComponent } from "./experience/experience.component";
import { StacksComponent } from "./stacks/stacks.component";

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    standalone: true,
    imports: [CommonModule, StacksComponent, ExperienceComponent]
})
export class AboutComponent{
    constructor(private themeService: ThemeService) {
    }

    get isDarkMode(): boolean {
        return this.themeService.isDarkMode();
    }
}
