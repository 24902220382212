import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { ThemeService } from 'src/app/theme/theme.service';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class NavComponent {

  constructor(private themeService: ThemeService) {
  }

  public logoUrl = '../../assets/NavLogo.png'


  @HostBinding('class.theme-dark') get isDarkMode() {
    return this.themeService.isDarkMode();
  }
  @HostBinding('class.theme-light') get isLightMode() {
    return !this.themeService.isDarkMode();
  }

  toggleTheme(event: MouseEvent): void {
    this.themeService.setTheme(!this.themeService.isDarkMode());
  }

  toggleLogo(toggle: boolean) {
    if (toggle == true) {
      this.logoUrl = '../../assets/NavLogoFilled.png'
    }
    else {
      this.logoUrl = '../../assets/NavLogo.png'
    }
  }

}
