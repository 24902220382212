<section class="d-flex justify-content-center">
    <div class="col-12 scroller">
      <ul class="inner-scroller">
        <li>HTML</li>
        <li>CSS</li>
        <li>ANGULAR</li>
        <li>.NET</li>
        <li>SQL</li>
        <li>TYPESCRIPT</li>
        <li>C#</li>
        <li>PYTHON</li>
        <li>FIREBASE</li>
        <li>AZURE</li>
        <li>GIT</li>
        <li>HTML</li>
        <li>CSS</li>
        <li>ANGULAR</li>
        <li>.NET</li>
        <li>SQL</li>
        <li>TYPESCRIPT</li>
        <li>C#</li>
        <li>PYTHON</li>
        <li>FIREBASE</li>
        <li>AZURE</li>
        <li>GIT</li>
      </ul>
    </div>
  </section>