<div class="row justify-content-center">
  <h1 class="exp-header col-12 text-center">Work Experience</h1>
</div>
<div class="container-fluid">
  <section class="experience-section">
    <div class="card">
      <div class="row">
        <div class="card-item col-sm-12 col-md-12 col-xl-4">
          <div class="">
            <h2 class="fs-1">FullStack Developer</h2>
            <p class="">{{ "@" }} Innovation Integration Inc</p>
            <p class="">Remote, United States</p>
          </div>
          <button class="btn">View Company</button>
        </div>
        <div class="company-info col-sm-12 col-md-8">
          <h2 class="text-center fs-1">Description</h2>
          <p>
            1 of 3 Devs on the IMI team building a cloud-hosted Training and Data Management
            platform for a DOD Client in the Defense Industry.
          </p>
          <div class="row g-0 ms-3">
            <div class="mx-auto">
              <span class="badge rounded-pill py-2 px-4 me-3 my-2 fs-6"
                >C#</span
              >
              <span class="badge rounded-pill py-2 px-4 me-3 my-2 fs-6"
                >.Net</span
              >
              <span class="badge rounded-pill py-2 px-4 me-3 my-2 fs-6"
                >Blazor</span
              >
              <span class="badge rounded-pill py-2 px-4 me-3 my-2 fs-6"
                >SQL</span
              >
              <span class="badge rounded-pill py-2 px-4 me-3 my-2 fs-6"
                >REST</span
              >
              <span class="badge rounded-pill py-2 px-4 me-3 my-2 fs-6"
                >Azure</span
              >
              <span class="badge rounded-pill py-2 px-4 me-3 my-2 fs-6"
                >Git</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
