import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { ThemeService } from 'src/app/theme/theme.service';

@Component({
  selector: 'app-stacks',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './stacks.component.html',
  styleUrl: './stacks.component.scss'
})
export class StacksComponent {

  outerCursorTop: string = "";
  outerCursorLeft: string = "";
  innerCursorTop: string = "";
  innerCursorLeft: string = "";

  constructor(private themeService: ThemeService) { }

  get isDarkMode(): boolean {
    return this.themeService.isDarkMode();
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove($event: { clientY: number; clientX: number; }) {
    const cursorWidth = 35;
    const maxX = window.innerWidth - cursorWidth;
    const offsetY = Math.min($event.clientY - 17.5) + "px";
    const offsetX = Math.min($event.clientX - 17.5, maxX) + "px";

    this.outerCursorTop = offsetY;
    this.outerCursorLeft = offsetX;

    this.innerCursorTop = Math.min($event.clientY - 5) + "px";
    this.innerCursorLeft = Math.min($event.clientX - 5, maxX) + "px";
  }

}
