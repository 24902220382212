import { Component } from '@angular/core';

@Component({
  selector: 'app-scroller',
  standalone: true,
  imports: [],
  templateUrl: './scroller.component.html',
  styleUrl: './scroller.component.scss'
})
export class ScrollerComponent {

}
