<section class="stack-section container-fluid">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-lg-4 col-xl-4 col-xxl-3 mb-5">
      <div class="avatar-frame">
      </div>
      <div class="stack" [ngClass]="isDarkMode ? 'dark' : 'light'">
        <div class="d-flex justify-content-center">
          <span class="d-flex stack-icon justify-content-center align-items-center">
            <i class="fa-solid fa-laptop-code" style="color: #000000;"></i>
          </span>
        </div>
        <h1 class="stack-header text-center pt-5 fw-bold text-body-secondary">FrontEnd</h1>
        <div class="stack-header-text p-5 text-body-secondary">
          Need to visualize it? <br>I can prototype it
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn stack-btn mt-4 mb-5" style="color:#00b084">Learn More <i class="fa-solid fa-up-right-from-square" style="color: #00b084;"></i></button>
        </div>
      </div>
    </div>    
    <div class="col-sm-12 col-lg-4 col-xl-4 col-xxl-3 mb-5">
      <div class="stack" [ngClass]="isDarkMode ? 'dark' : 'light'">
        <div class="d-flex justify-content-center">
          <span class="d-flex stack-icon justify-content-center align-items-center">
            <i class="fa-solid fa-database" style="color: #000000;"></i>
          </span>
        </div>
        <h1 class="stack-header text-center pt-5 fw-bold text-body-secondary">BackEnd</h1>
        <div class="stack-header-text p-5 text-body-secondary">
          Need data moving? <br> I can get it flowing.
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn stack-btn mt-4 mb-5" style="color:#00b084">Learn More <i class="fa-solid fa-up-right-from-square" style="color: #00b084;"></i></button>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-4 col-xl-4 col-xxl-3 mb-5">
      <div class="stack" [ngClass]="isDarkMode ? 'dark' : 'light'">
        <div class="d-flex justify-content-center">
          <span class="d-flex stack-icon justify-content-center align-items-center">
            <i class="fa-solid fa-cubes" style="color: #000000;"></i>
          </span>
        </div>
        <h1 class="stack-header text-center pt-5 fw-bold text-body-secondary">FullStack</h1>
        <div class="stack-header-text p-5 text-body-secondary">
          Need the best of both? <br>
          I build it and deploy it.
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn stack-btn mt-4 mb-5" style="color:#00b084">Learn More <i class="fa-solid fa-up-right-from-square" style="color: #00b084;"></i></button>
        </div>
      </div>
    </div>
  </div>
</section>