import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private isdarkMode: boolean = true;
  private bodyElement: HTMLElement = document.body;

  constructor() {
    // Retrieve theme preference from localStorage during initialization
    const storedTheme = window.localStorage.getItem('DarkMode');
    if (storedTheme) {
      this.isdarkMode = JSON.parse(storedTheme);
      this.updateTheme();
    }
    else{
      this.isdarkMode=true;
      this.updateTheme();
    }
  }

  isDarkMode():boolean {
    return this.isdarkMode;
  }

  setTheme(darkModeSelected: boolean):void {
    this.isdarkMode = darkModeSelected;
    window.localStorage.setItem('DarkMode', JSON.stringify(this.isdarkMode));
    this.updateTheme();
  }

  private updateTheme():void {
    if (this.isdarkMode) {
      this.bodyElement.classList.remove('theme-light');
      this.bodyElement.classList.add('theme-dark');
    }
    else {
      this.bodyElement.classList.remove('theme-dark');
      this.bodyElement.classList.add('theme-light');
    }
  }
}
